<template>
  <div class="page" style="max-width: 600px; margin: 0 auto;height: 100%;" v-if="IvstInfo">

    <div v-if="step==0&&false" style="background-color: #8dcffc;height: 100%;">


      <div style="position: relative;height: 100%;">
        <img src="../../../public/img/wj02bg.jpg" style="width: 100%;height: 100%; vertical-align: middle;" />
        <div style="width: 100%; height: 100%; position: absolute; top: 0; z-index: 1;">
          <div style="height: 25vw;">

          </div>
          <div style="position: relative; width: 80%; margin: 0 auto; ">
            <div :class="['stitem',ckeckedXX==item.xxid?'stitemckd':'']" @click="ckeckedXX=item.xxid"
                 v-for="(item,index) in tm0List" :key="index" style="">
              <div
                  style=" line-height: 30px; color: #FFFFFF; font-size: 14px; font-weight: bold; text-align: center;">
                {{item.title}}
              </div>
              <div
                  style="width: 40px;height: 40px; margin: 0 auto; border-radius: 50%; overflow: hidden;">
<!--                <img v-if="index<=6" :src="'./img/wjst'+index+'.png'" style="width: 100%; " />-->
                <img :src="'./img/wjst0.png'" style="width: 100%; " />
              </div>
              <div
                  style="width: 12px; height: 12px;margin: 0 auto; margin-bottom: 5px; margin-top: 5px; border: 1px solid #fff; border-radius: 50%; overflow: hidden;">
                <img v-if="ckeckedXX==item.xxid" :src="'./img/check.png'"
                     style="width: 100%; transform: translateY(-2px); " />
              </div>

            </div>



          </div>
          <div @click="goNext(1)" style=" width: 50%; margin: 0 auto; margin-top: 30px;">
            <img src="../../../public/img/wj02_btn.png" style="width: 100%; vertical-align: middle; " />
          </div>

        </div>
      </div>

    </div>


    <div v-if="planInfo">
      <div style="padding: 20px;">
        <div class="bolder">
          {{planInfo.plan_name}}
        </div>
        <div class="wjdesc">
          {{planInfo.sta_time}} {{planInfo.end_time}}
        </div>
      </div>

      <div style="">
        <div class="wjitem flexBetween" @click="goIvst(item)" v-for="(item,idx) in tempList">
          <div class="wjtitle">{{item.ivst_title}}</div>

          <div class="wjdesc flexBetween">
            <!--						2021-01-01 至 2022-01-01-->
            <span class="wjstatus" v-if="item.joined" style="color: #009900;">已参与</span>
            <span class="wjstatus" style="color: orangered;" v-else>未参与</span>

            <div class="righticon">
              <img :src="'./img/right.png'">
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      plan: {
        pmid: 0,
        plan_id: 0,
        uid: 0,
        utype: "",
        ftype: ""
      },
      ivst_id: 0,
      planInfo: null,
      IvstInfo: null,
      tempList: [],
      color: ["1fa4fb", "3e6bff", "139efc", "149efc", "38aaf5", "2ea7f9", "3f6cfe", "109ffe", "4375ff"],
      tm0List: [],
      ckeckedXX: 0,
      step: 0,
      joined: false
    }
  },
  mounted() {
    //1%1%s%1%m pmid,planid,utype(s学生,p家长,e企业),uid,fromtype(m小程序，s短信，e邮件)
			
			if (this.$route.params.id) {
				let ids = this.$route.params.id.split('.')
				let plan = {
					pmid: ids[0],
					plan_id: ids[1],
					utype: ids[2],
					juid: ids[3],
					ftype: ids[4],
				}
				console.log(this.$route.query.uid)
				if(this.$route.query.uid){
					 this.$http.post("api/stu_oid_newid", {oid:this.$route.query.uid}).then(res => {
						 if(res.data&&res.data.id){
							plan.juid = res.data.id
							this.plan = plan
						
							this.getPlanInfo()
						 }else{
							 alert("未检测到毕业状态！")
						 }
					 })
					
				}else{
					this.plan = plan
					this.getPlanInfo()
				}
				
			}
			
			console.log(this.plan)

    let step = this.getQuery("state")

    if (step) {
      this.step = step
    }




  },
  methods: {
    getPlanInfo() {
      this.$http.post("api/h5_ivst_plan", this.plan).then(res => {
        if (res.data.temp&&res.data.temp.length>0) {
          this.planInfo = res.data;
          this.tempList = res.data.temp
          document.title = this.tempList[0].ivst_title
          this.ivst_id = this.tempList[0].id
          this.getIvst()
        }else {
          alert("您无需填写该问卷~")
        }

      })
    },
    getIvst() {
      this.$http.post("api/h5_ivst_one", {
        temp_id: this.ivst_id,
        pmid: this.plan.pmid
      }).then(res => {
        if (res.data.answers && res.data.answers.length > 0) {
          this.joined = true
        }
        this.IvstInfo = res.data
        this.tm0List = JSON.parse(res.data.tm_list[0].tm_content)
      })
    },

    goNext(step) {
      let _this = this
      if (this.joined) {
        alert("您已参与过，请勿重复参与！")
      } else {

          this.step = step
          this.$nextTick(() => {
            $(window).scrollTop(0)
            if (step == 1) {
              if (_this.ckeckedXX) {

                localStorage.setItem("ckeckedXX", _this.ckeckedXX)
                _this.$router.push("/ivst?plan=" + _this.$route.params.id + "&id=" + _this.ivst_id)
              } else {
                _this.$message.error("请先选择当前状态")
                _this.step = 0
                return
              }
            }
          })


      }

    },
    goIvst(row) {
      console.log(this.$route.params.id)
	  if(this.plan.plan_id){
		   this.$router.push("/ivst?plan=" + this.$route.params.id + "&id=" + row.id + "&pmid=" + this.plan.pmid+"&uid="+this.plan.juid)
	  }else{
		   this.$router.push("/ivst?plan=" + this.$route.params.id + "&id=" + row.id + "&pmid=" + this.plan.pmid)
	  }
     
    },
    showToast(msg) {
      $("#toast").text(msg).show().animate({
        opacity: 1
      }, 200, "linear", function() {
        setTimeout(() => {
          $("#toast").animate({
            opacity: 0
          }, "linear", 3000, function() {
            $("#toast").hide()
          })
        }, 2000)
      })
    },


  },
}
</script>

<style>
.stitem {
  width: 45%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 10px;
  border-radius: 4px;
  box-sizing: border-box;
  border: 2px solid #1fa4fb;
  background-color: #1fa4fb;
}

.stitemckd {
  border: 2px solid #f9b379;
}

.stitem:nth-child(2n+1) {
  margin-right: 10%;
}

.bolder {
  font-weight: bolder;
}

.wjitem {
  border-bottom: 1px solid #eee;
  font-size: 14px;
  padding: 10px 15px;
  position: relative;
}

.wjdesc {
  font-size: 12px;
  padding: 10px 0;
}

.wjstatus {
  /*float: right;*/
  margin-right: 10px;
  vertical-align: middle;
}

.wjtitle {
  margin-right: 30px;
}

.wjitem:active {
  background-color: #eee;
}

.righticon {
  width: 20px;
  height: 20px;
  /*position: absolute;*/
  /*top: 25px;*/
  /*right: 10px;*/
}

.righticon img {
  width: 100%;
  height: 100%;
}

.wjitem:nth-child(1) {
  border-top: 1px solid #eee;
}
</style>
